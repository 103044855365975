import { language_data } from '../components/content-data/language_data';
import { tool_data } from '../components/content-data/tool_data';

const Data = () => {
  const getGraphPercent = (n) => {
    const graph_max = 30;           // グラフの最大値
    return Math.round((n / graph_max) * 100);
  };
  const getGraphStyle = (n) => {
    return {'--my_percent': getGraphPercent(n) + '%'};
  }
  const languages = language_data.map((item, k) =>
    <li className="data-lists-item" key={k}>
      <div className="icon"><i className={item.icon}></i></div>
      <div className="type point-font">{item.name}</div>
      <div className="year">{item.period}年</div>
      <div className="bar point-font"><span style={getGraphStyle(item.period)}></span></div>
    </li>
  );
  const tools = tool_data.map((item, k) => 
  <li className="data-lists-item" key={k}>
    <div className="icon"><i className={item.icon}></i></div>
    <div className="type point-font">{item.name}</div>
    <div className="year">{item.period}年</div>
    <div className="bar point-font"><span style={getGraphStyle(item.period)}></span></div>
  </li>
  );

  return (
    <>
    <section className="contants-item">
      <h2 className="contents-title data point-font">DATA</h2>
      <div className="contents" id="data">
        <h3 className="data-title">言語・環境利用年数</h3>
        <div className="data-lists-container">
          <div className="contents-inner data" id="languageListContainer">
            <ul className='data-lists language'>{languages}</ul>
          </div>
          <div className="contents-inner data" id="toolListContainer">
            <ul className='data-lists tools'>{tools}</ul>
          </div>
        </div>
      </div>
    </section>
    </>
  );
};
export default Data;