const About = () => {
  return (
    <>
    <section className="contants-item">
      <h2 className="contents-title about point-font">ABOUT</h2>
      <div className="contents" id="about">
        <div className="contents-inner about">
          <figure className="image">
            <img src="/images/prof1.jpg" alt="" />
          </figure>
          <div className="text">
            <dl>
              <dt><i className="ri-user-2-fill"></i>名前</dt>
              <dd>AmizLucky<br /><span style={{fontSize: '1rem'}}>アミッツラッキー</span></dd>
              <dt><i className="ri-map-pin-2-fill"></i>出身地</dt>
              <dd>神奈川県</dd>
              <dt><i className="ri-chat-1-fill"></i>自己紹介</dt>
              <dd>
                <p>はじめまして、ポートフォリオをご覧いただきありがとうございます。</p>
                <p>私はWeb制作を生業としメイン作業領域は、Webサイトのユーザーの目に触れる箇所になります。</p>
                <p>ここ数年は、サイト運営者の方が利用するサイトの管理ページを作成するようになり、フロントエンドからバックエンドWebサイトの構築にかかわるすべての範囲を、一人で行う機会も増えてきています。</p>
                <p>
                  ゲーム(プレイ・作成)、読書(漫画・技術書)、楽器演奏、飼い猫・飼い犬との触れ合い、自分用データスクレイピング、自分用Webアプリ開発などが趣味です。<br />
                  気になったものはとりあえず試してみようとする好奇心・探求心が強い性格と自負しています。
                </p>
              </dd>
              <dt><i className="ri-code-s-slash-fill"></i>言語</dt>
              <dd className="icons">
                <i className="devicon-html5-plain"></i>
                <i className="devicon-css3-plain"></i>
                <i className="devicon-javascript-plain"></i>
                <i className="devicon-jquery-plain"></i>
                <i className="devicon-php-plain"></i>
              </dd>
              <dt><i className="ri-hammer-fill"></i>道具</dt>
              <dd className="icons">
                <i className="devicon-windows11-original"></i>
                <i className="devicon-chrome-plain"></i>
                <i className="devicon-vscode-plain"></i>
                <i className="devicon-photoshop-plain"></i>
                <i className="devicon-illustrator-plain"></i>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </section>

    </>
  );
};
export default About;