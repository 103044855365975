// import './App.css';
import About from './components/About';
import Works from './components/Works';
import Data from './components/Data';

function App() {
  return (
    <>
      <header className="page-head" id="pageHead">
        <h1 className="page-name point-font">PORTFOLIO</h1>
      </header>
      <About />
      <Works />
      <Data />
      <nav className="global-navi">
        <ul>
          <li><a href="/#about" className=" point-font"><span className="icon"><i className="ri-user-fill"></i></span><span className="text">ABOUT</span></a></li>
          <li><a href="/#works" className=" point-font"><span className="icon"><i className="ri-folder-6-fill"></i></span><span className="text">WORKS</span></a></li>
          <li><a href="/#data" className=" point-font"><span className="icon"><i className="ri-line-chart-line"></i></span><span className="text">DATA</span></a></li>
        </ul>
      </nav>
    </>
  );
}

export default App;
