export const works_data = [
  { src_pc: 'sanc-pc.jpg', src_sp: 'sanc-sp.jpg', name: 'SANC.', industry: 'Lotus・TVR正規ディーラー', scope: 'バックエンド/フロントエンド', language: 'HTML/CSS/JavaScript/PHP/Wordpress', link: 'https://sanc-auto.com/' },
  { src_pc: 'cubique-pc.jpg', src_sp: 'cubique-sp.jpg', name: '株式会社 キュビック', industry: '輸入車正規販売店', scope: 'フロントエンド', language: 'HTML/CSS/JavaScript', link: 'http://cubique.jp/' },
  { src_pc: 'motosanc-pc.jpg', src_sp: 'motosanc-sp.jpg', name: 'MOTO-SANC.', industry: 'バイク買取', scope: 'バックエンド/フロントエンド', language: 'HTML/CSS/JavaScript/Wordpress', link: 'http://motosanc.com/' },
  { src_pc: 'apache-pc.jpg', src_sp: 'apache-sp.jpg', name: 'apache', industry: '芸能プロダクション', scope: 'バックエンド(部分)/フロントエンド', language: 'HTML/CSS/JavaScript/Wordpress', link: 'https://apache2001.co.jp/' },
  { src_pc: 'hosttv-pc.jpg', src_sp: 'hosttv-sp.jpg', name: 'HOST-TV', industry: '動画配信', scope: 'フロントエンド', language: 'HTML/CSS/JavaScript/PHP', link: 'http://host-tv.com/' },
  { src_pc: 'axltokyo-pc.jpg', src_sp: 'axltokyo-sp.jpg', name: 'AXL TOKYO', industry: '映像制作', scope: 'バックエンド/フロントエンド', language: 'HTML/CSS/JavaScript/Wordpress', link: 'http://axl-tokyo.com/' },
  { src_pc: 'studioaxl-pc.jpg', src_sp: 'studioaxl-sp.jpg', name: 'studio AXL', industry: 'フォトスタジオ', scope: 'バックエンド/フロントエンド', language: 'HTML/CSS/JavaScript/Wordpress', link: 'http://studio-axl.com/' },
  { src_pc: 'kyouseinetwork-pc.jpg', src_sp: 'kyouseinetwork-sp.jpg', name: '共生ネットワーク', industry: '特定非営利活動法人', scope: 'バックエンド/フロントエンド', language: 'HTML/CSS/JavaScript/Wordpress', link: 'http://kyousei-network.net/' },
  { src_pc: 'riverlaw-pc.jpg', src_sp: 'riverlaw-sp.jpg', name: '小川・大川法律事務所', industry: '法律事務所', scope: 'バックエンド/フロントエンド', language: 'HTML/CSS/JavaScript', link: 'https://river-law.jp/' },
  { src_pc: 'earldeco-pc.jpg', src_sp: 'earldeco-sp.jpg', name: 'earlclub(旧EARLDECO)', industry: '店舗内装施工等', scope: 'バックエンド/フロントエンド', language: 'HTML/CSS/JavaScript/Wordpress', link: 'http://earlde.com/' },
  { src_pc: 'knot-pc.jpg', src_sp: 'knot-sp.jpg', name: 'DESIGN LABEL KNOT', industry: '店舗企画・デザイン・設計等', scope: 'バックエンド/フロントエンド', language: 'HTML/CSS/JavaScript/Wordpress', link: 'http://knot-knot-knot.com/' },
  { src_pc: 'gfp-pc.jpg', src_sp: 'gfp-sp.jpg', name: 'GOLF＆FITNESS POINT 芝浦', industry: 'ゴルフスクール、フィットネスレッスン', scope: 'フロントエンド', language: 'HTML/CSS/JavaScript', link: 'http://gfp.jp/' }
];
