// import { Link } from 'react-router-dom';
import { works_data } from '../components/content-data/works_data';

const Works = () => {
    // [サムネイル切り替えイベント] ----------------
    const thumbChange = e => {
      const parent = e.target.parentNode.parentNode.parentNode;
      const thumb = parent.querySelector('.thumb');
      const select = parent.querySelector('.select');
      const check = select.querySelector('input[type=checkbox]');
      check.addEventListener('change', (e) => {
        (e.target.checked) ? thumb.classList.add('sp') : thumb.classList.remove('sp')
      });
    };

    // [キャプチャスライド] -----------------------
    const slideStart = e => {
      const target = e.target;
      // フル画像が設置済みの場合は削除する
      const full_images = document.querySelectorAll('.scroll-image');
      if (full_images.length) {
        full_images.forEach(function(e) {
          e.remove();
        });
      }
      // 画像設置
      let image = new Image();
      const dir_path = './images/works/full/';
      const height = target.clientHeight;
      let is_sp = target.classList.contains('sp');
      let src = target.getAttribute('src');
      if (!src) return;
      src = src.split('/');
      src = src[src.length - 1].split('-')[0];
      src = (is_sp) ? `${dir_path + src}-sp.jpg` : `${dir_path + src}-pc.jpg`;
      image.src = src;

      // 設置後に画像の高さを取得してスクロール量を決める
      image.addEventListener('load', function() {
        const parent = target.parentNode;
        parent.append(this);
        let limit_height = Math.floor(this.height - height);
        // 設置した画像の高さで処理振り分け
        // ※高さが足りない場合は設置せずに即削除
        // ※その他は高さによって速度調整
        if (limit_height > height) {
          this.setAttribute('class', 'scroll-image');
          let move_time;
          if (limit_height < 100) {
          } else if (limit_height > 101 && limit_height < 200) {
            move_time = 5000;
          } else if (limit_height > 201 && limit_height < 300) {
            move_time = 6000;
          } else if (limit_height > 301 && limit_height < 400) {
            move_time = 7000;
          } else if (limit_height > 401 && limit_height < 500) {
            move_time = 8000;
          } else if (limit_height > 501 && limit_height < 600) {
            move_time = 9000;
          } else if (limit_height > 601 && limit_height < 700) {
            move_time = 10000;
          } else if (limit_height > 701 && limit_height < 800) {
            move_time = 14000;
          } else if (limit_height > 801 && limit_height < 900) {
            move_time = 16000;
          } else if (limit_height > 901 && limit_height < 1000) {
            move_time = 17000;
          } else if (limit_height > 1001 && limit_height < 1100) {
            move_time = 18000;
          } else if (limit_height > 1101 && limit_height < 1200) {
            move_time = 22000;
          } else {
            move_time = 60000;
          }
          image.setAttribute('style', `--myheight: ${-limit_height}px; animation-duration: ${move_time}ms;`);
        } else {
          this.remove();
          image = null;
        }
      });

    };
    const slideEnd = e => {
      const full_images = document.querySelectorAll('.scroll-image');
      if (full_images.length) {
        full_images.forEach(function(e) {
          e.remove();
          e = null;
        });
      }
    };
  
  /**
   * https://qiita.com/sabawo/items/30415d9586499d8b0038
   * ループするときに『Warning: Each child in a list should have a unique "key" prop.』が出る場合の対処
   * ループする要素の親に『 key(key=値) 』を持たせたら解決する※Reactの仕様っぽいこれで要素の削除とかを監視するためのよう
  */
  const list_item = works_data.map((item, k) =>
    <li className="work-lists-item" key={k}>
      <div className="thumb" onMouseEnter={slideStart} onMouseLeave={slideEnd}>
        <img src={'./images/works/thumb/' + item.src_pc} className="pc" alt="" />
        <img src={'./images/works/thumb/' + item.src_sp} className="sp" alt="" />
      </div>
      <div className="control">
        <label className="select"><input type="checkbox" className="works-thumb-select" onChange={thumbChange} /><span className="point-font"></span></label>
      </div>
      <div className="text">
        <div className="name">{item.name}</div>
        <div className="industry">{item.industry}</div>
        <div className="scope">{item.scope}</div>
        <div className="language">{item.language}</div>
        <div className="link"><a href={item.link} rel="noreferrer" target="_blank">サイトを確認する<i className="ri-external-link-line"></i></a></div>
      </div>
    </li>
  );
  return (
    <>
      <section className="contants-item">
        <h2 className="contents-title works point-font">WORKS</h2>
        <div className="contents" id="works">
          <div className="contents-inner works" id="worksListsContainer"></div>
          <ul className="work-lists">{list_item}</ul>
        </div>
      </section>
    </>
  );
};
export default Works;